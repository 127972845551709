import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

// the css
import "../styles/style.css"

// title differs dependent on current page
function dynTitle() {
  const path = typeof window !== "undefined" ? window.location.pathname : "/"
  if (path === "/")
    return "Kanzlei Schweikert - Strafverteidiger & Rechtsanwalt Bad Aibling"
  if (path === "/cv")
    return "Rechtsanwalt Schweikert - Strafrecht, Zivilrecht und Arbeitsrecht"
  if (path === "/bereiche")
    return "Wirtschaftskanzlei mit Strafrecht, Erbrecht, Steuerrecht, Baurecht, Immobilienrecht und Vertrragsgestaltung"  
  if (path === "/kanzlei")
    return "Kanzlei Schweikert - Bad Aibling, M&uuml;nchen, Traunstein, Rosenheim, Dachau"
  if (path === "/details")
    return "Kanzlei Schweikert, Anwalt & Strafverteidiger Bad Aibling"
    
  if (path === "/impressum")
    return "Kanzlei Schweikert - Anwaltskanzlei Bad Aibling"
  return "Kanzlei Schweikert"
}

// title differs dependent on current page
const MenuEntry = props => (
  <span className="item">
    <Link activeClassName={"active"} {...props} />
  </span>
)

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <title>{dynTitle()}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="google-site-verification"
          content="zIspc8nwb2QW9tbHiDCvUN7MDsULCkExal-WovCeyf8"
        />
        <meta name="audience" content=" Alle" />
        <meta
          name="author"
          content="Rechtsanwalt Schweikert, Lagerhausstr.12, 83043 Bad Aibling"
        />
        <meta name="copyright" content="Kanzlei Schweikert" />
        <meta
          name="description"
          content="Rechtsanwalt Friedrich Schweikert, Ihr Strafverteidiger und Rechtsanwalt in Bad Aibling - NOTDIENST: 08061 9388481 - Erfahren Sie mehr Ã¼ber Anwalt Schweikert"
        />
        <meta
          name="keywords"
          content="Rechtsanwalt, Anwalt, Strafverteidiger, Verteidiger, Strafrecht, Erbrecht, Gesellschaftsrecht, Strafrecht, Immobilienrecht, Arbeitsrecht, Steuerrecht, Bad Aibling, Rosenheim, Oberbayern, Traunstein, München"
        />
        <meta name="page-topic" content="Recht" />
        <meta name="page-type" content="Anzeige" />
        <meta name="publisher" content="Rechtsanwalt Friedrich Schweikert" />
        <meta name="revisit-after" content="30 days" />
        <meta name="robots" content=" INDEX,FOLLOW" />
        <meta name="geo.region" content="DE-BY" />
        <meta name="geo.placename" content="Bad Aibling" />
        <meta name="geo.position" content="47.860711;12.001394" />
        <meta name="ICBM" content="47.860711, 12.001394" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.png" />
      </Helmet>
      <div className="page rem-of-13">
        <div className="header">
          <div className="banner">
            Kanzlei Schweikert
          </div>
          <div className="navi">
            <MenuEntry to="/">Willkommen</MenuEntry>
            <MenuEntry to="/cv">RA Schweikert</MenuEntry>
            <MenuEntry to="/bereiche">Bereiche</MenuEntry>
            <MenuEntry to="/kanzlei">Die Kanzlei</MenuEntry>
            <MenuEntry to="/details">Details</MenuEntry>
            <MenuEntry to="/impressum">Impressum</MenuEntry>
          </div>
        </div>
        <div className="main">
        {children}
        </div>
        <div className="footer">
          <Link
            to="/impressum"
            style={{ color: "#FFFFFF", fontWeight: "normal" }}
          >
            Lagerhausstr.12, Postfach 1248 in 83036 Bad Aibling,
            Tel 08061-9386895, Fax 08061-9386894,
            <img
              src="/email.gif"
              alt="Emailadresse"
              style={{ verticalAlign: "middle" }}
              border="0"
            ></img>
          </Link>
        </div>
      </div>
    </>
  )
}
