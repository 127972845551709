import React from "react"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <div className="deco">
          <img
            src="/bereiche.jpg"
            alt="T&auml;tigkeitsbereiche"
          />        
      </div>
      <div className="content">
        <h1>Willkommen bei der Kanzlei Schweikert</h1>
        <p className="no-margin">
            Bei uns k&ouml;nnen Sie eine ganzheitliche, geschlossene und in sich
            stimmige Beratung erwarten, ohne dauernd den Anwalt wechseln zu
            m&uuml;ssen. Wir bieten f&auml;cher&uuml;bergreifendes Wissen mit
            den Schwerpunkten <b>Strafrecht</b>, <b>Arbeitsrecht</b>,{" "}
            <b>Erbrecht</b>, <b>Verkehrsrecht</b>,<b>Vertragsrecht</b>,{" "}
            <b>allgemeines Zivilrecht</b> und <b>Steuerstrafrecht</b>.
        </p>
        <p>
            Wir scheuen den Kontakt zu Ihnen auch au&szlig;erhalb der Kanzlei
            nicht und begeben uns wenn n&ouml;tig direkt zu den
            Schaupl&auml;tzen.
        </p>
        <p>
            Mit Rechtsanwalt und Strafverteidiger Friedrich Schweikert erwartet
            Sie ein verschwiegener, ehrlicher und redegewandter
            Pr&auml;dikatsjurist{" "}
            <span className="rem-of-10">
              ( <img src="/sum.gif" alt="" height="9" /> &uuml;ber 16
              Punkte in den Examina)
            </span>
            , der auch &uuml;ber den juristischen Tellerrand schaut, und dem
            Leben n&auml;her steht als grauen Theorien.
            <br />
            Unterst&uuml;tzt wird dieser von den Sachbearbeitern Robert
            Ramachers (Jurist) und Ute Schiefer (Fachwirtin). Au&szlig;erdem ist
            die Kanzlei Schweikert seit dem Jahr 2014 Ausbildungsbetrieb.
          </p>
          <p>
            <b>Allgemeine Telefonnummer: +49 8061 9386895</b>
          </p>
          <p className="rem-of-16 emergency bold no-margin">
              NOTFALLNUMMER: +49 8061 9388481
        </p>
      </div>
    </Layout>
  )
}
